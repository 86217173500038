import '../css/bootstrap.min.css';
import '../css/magnific-popup.css';
import '../css/materialdesignicons.min.css';
import '../css/owl.carousel.min.css';
import '../css/owl.theme.default.min.css';
import '../css/style.css';
import '../css/colors/default.css';

import './jquery-global';
import 'jquery.easing/jquery.easing';
import 'scrollspy';
import 'magnific-popup/dist/jquery.magnific-popup';
import * as owl from './owl.carousel.min.js';
import * as owlInit from './owl.init.js';
import * as feather from './feather.min.js';
import mediumZoom from 'medium-zoom'
import * as magnificInit from './magnific.init.js';


document.querySelector('#year').innerHTML = new Date().getFullYear();

! function($) {
    "use strict";
    // Loader 
    $(window).on('load', function() {
        $('#status').fadeOut();
        $('#preloader').delay(350).fadeOut('slow');
        $('body').delay(350).css({
            'overflow': 'visible'
        });
    });

    // Menu
    $('.navbar-toggle').on('click', function(event) {
        $(this).toggleClass('open');
        $('#navigation').slideToggle(400);
    });

    $('.navigation-menu>li').slice(-1).addClass('last-elements');

    $('.menu-arrow,.submenu-arrow').on('click', function(e) {
        if ($(window).width() < 992) {
            e.preventDefault();
            $(this).parent('li').toggleClass('open').find('.submenu:first').toggleClass('open');
        }
    });

    $(".navigation-menu a").each(function() {
        if (this.href == window.location.href) {
            $(this).parent().addClass("active");
            $(this).parent().parent().parent().addClass("active");
            $(this).parent().parent().parent().parent().parent().addClass("active");
        }
    });

    // Clickable Menu
    $(".has-submenu a").click(function() {
        if (window.innerWidth < 992) {
            if ($(this).parent().hasClass('open')) {
                $(this).siblings('.submenu').removeClass('open');
                $(this).parent().removeClass('open');
            } else {
                $(this).siblings('.submenu').addClass('open');
                $(this).parent().addClass('open');
            }
        }
    });

    $('.mouse-down').on('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top - 72
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });

    //Sticky
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 50) {
            $(".sticky").addClass("nav-sticky");
        } else {
            $(".sticky").removeClass("nav-sticky");
        }
    });

    // Back to top
    $(window).scroll(function() {
        if ($(this).scrollTop() > 100) {
            $('.back-to-top').fadeIn();
        } else {
            $('.back-to-top').fadeOut();
        }
    });
    $('.back-to-top').click(function() {
        $("html, body").animate({ scrollTop: 0 }, 3000);
        return false;
    });

    //Feather icon
    feather.replace()

    function addAnchorBehavior(fromEl, toEl) {
        var el = document.getElementById(fromEl);
        el.onclick = function() {
            var yOffset = -25;
            var element = document.getElementById(toEl);
            var y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });

        }
    }

    function registerForm(formId, btnId, statusId) {
        // get the form elements defined in your form HTML above
        var form = document.getElementById(formId);
        var button = document.getElementById(btnId);
        var status = document.getElementById(statusId);

        // Success and Error functions for after the form is submitted

        function success() {
            form.reset();
            button.style = "display: none ";
            status.innerHTML = "Thanks!";
        }

        function error() {
            status.innerHTML = "Oops! There was a problem.";
        }

        // handle the form submission event

        form.addEventListener("submit", function(ev) {
            ev.preventDefault();
            var data = new FormData(form);
            ajax("POST", form.action, data, success, error);
        });
    }

    // helper function for sending an AJAX request

    function ajax(method, url, data, success, error) {
        var xhr = new XMLHttpRequest();
        xhr.open(method, url);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = function() {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                success(xhr.response, xhr.responseType);
            } else {
                error(xhr.status, xhr.response, xhr.responseType);
            }
        };
        xhr.send(data);
    }


    function bindNavigation() {
        registerForm("requestCall", "btnRequestCall", "txtRequestCall");
        registerForm("frmContact", "btnSubmit", "txtContact");

        addAnchorBehavior("logo", "home");
        addAnchorBehavior("navFeatures", "features");
        addAnchorBehavior("navIndustries", "industries");
        addAnchorBehavior("navResellers", "resellers");
        addAnchorBehavior("navTestimonials", "testimonial");
        addAnchorBehavior("navContact", "contact");
    }

    if (document.readyState !== 'loading') {
        bindNavigation();
    } else {
        window.addEventListener("DOMContentLoaded", function() {
            bindNavigation();
        });
    }

    var anchors = document.getElementsByClassName("demo-btn");
    for (var i = 0; i < anchors.length; i++) {
        var anchor = anchors[i];
        anchor.onclick = function() {
            Calendly.initPopupWidget({ url: 'https://calendly.com/dimescheduler/dime-scheduler-demo?primary_color=6e62b5' });
            return false;
        }
    }

    var anchors = document.getElementsByClassName("contact-btn");
    for (var i = 0; i < anchors.length; i++) {
        var anchor = anchors[i];
        anchor.onclick = function() {
            document.getElementById("frmContact").scrollIntoView({ behavior: "smooth", block: "start" });
            return false;
        }
    }

}(jQuery)

var Tawk_API = Tawk_API || {},
    Tawk_LoadStart = new Date();
(function() {
    var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/5f952455194f2c4cbeb8c390/default';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
})();

const zoom = mediumZoom({ margin: 80 });
zoom.attach("#imgps1", "#imgps2", "#imgps3", "#imgstart", "#imgps4")